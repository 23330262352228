<template>
<div>
<b-overlay variant="transparent" :show="loading"> 
    <b-overlay variant="transparent" :show="show">
        <template #overlay>
            <div class="report-loading-msg">
                <b-spinner v-if="showSpinner" size="lg" /><br>
                {{ message }}<br>
                <div v-if="showStatus" class="status-line">
                Status: {{ jobStatusRendered }}
                </div>
            </div>
        </template>
        <slot></slot>
    </b-overlay>
</b-overlay>
</div>
</template>

<script>
import {
  make_url,
  authenticated_request,
} from "../common";

export default {
    name: "JobOverlay",
    props: {
        job: {
            type: Object || Null,
            required: false,
        },
        delay: {
            type: Number,
            required: false,
            default: 30000,
        },
        message: {
            type: String,
            required: false,
            default: "Crunching the numbers for you ...",
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        showSpinner: {
            type: Boolean,
            required: false,
            default: true,
        },
        showStatus: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            jobStatus: null,
        };
    },
    methods: {
        getJobStatus() {
            if (!this.job?.job_id || !this.job?.queue) {
                return;
            }
            const vm = this;
            const url = make_url("/jobs/status");
            const params = {
                job_id: vm.job.job_id,
                job_queue: vm.job.queue,
            };
            authenticated_request({
                url: url,
                method: "get",
                params: params,
            }).then(
                function (response) {
                    vm.jobStatus = response.data.status;
            }).catch(
                function (error) {
                    console.log(error);
            });
        },
    },
    computed: {
        show() {
            return this.job?.job_id && this.jobStatus != "finished";
        },
        jobStatusRendered() {
            return this.jobStatus || "Waiting";
        },
    },
    watch: {
        jobStatus: function (newVal) {
            if (newVal) {
                if (newVal == "finished") {
                    console.log("Emitting 'finished' event");
                    this.$emit("finished");
                }
            }
        },
        job: function (newVal, oldVal) {
            if (newVal) {
                this.jobStatus = null;
            }
        },
    },
    mounted() {
        // call getJobStatus every second
        window.setInterval(() => {
            if (this.show) {
                this.getJobStatus();
            }
        }, this.delay);
    },
}
</script>


<style scoped>
.report-loading-msg {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    color: var(--gray);
}
.status-line {
    text-transform: capitalize;
}
</style>