<template>
<div>
    <back-button to="/admin/">Back to Settings</back-button>
    <div class="avo-header-text">CronJob Management</div>
    
    <hr />

    <b-card class="mb-1">
        
        <div class="avo-header-text mb-3">Currently scheduled cron jobs</div>
        <json-viewer :value="cron_jobs" expanded :expand-depth="8"/> 
    </b-card>
    
    <b-card class="mb-1">
        
        <div class="avo-header-text mb-3">Clear scheduled cron jobs</div>
        <b-button
            @click="clearCronJobs"
            :disabled="loading"
            variant="primary"
            class="m-2 avo-primary-btn"
            >Clear Scheduled Jobs</b-button
        >
    </b-card>
    
    <b-card class="mb-1">
        <div class="avo-header-text mb-3">Initialize cron jobs</div>
        <b-button
            @click="initCronJobs"
            :disabled="loading"
            variant="primary"
            class="m-2 avo-primary-btn"
            >Initialize Jobs</b-button
        >
    </b-card>

    <b-card class="mb-1">
    <job-overlay :job="job" message="Import job is running">
        <div class="avo-header-text mb-3">Run import now</div>
        
        <b-form-group
            label="Practice key"
            label-for="practice-key"
            description="Enter the 3-letter key for the practice to import, or leave blank to import all practices"
            >
            <b-form-input
                id="practice-key"
                v-model="practice_key"
                placeholder="Enter practice key"
                style="width: 200px"
                />
        </b-form-group>
        <b-button
            @click="runImportCron"
            :disabled="loading || (jobData !== null)"
            variant="primary"
            class="m-2 avo-primary-btn"
            >Run Import</b-button
        >
    </job-overlay>
    <hr />
        <div v-if="jobData">
        Job Started: 
        {{jobData}}
        </div>
    </b-card>

</div>
</template>

<script>
import { make_url, authenticated_request } from "../common";
import BackButton from "../components/BackButton.vue"
import JsonViewer from "vue-json-viewer"
import JobOverlay from "../components/JobOverlay.vue"

export default {
    name: "CronJobs",
    components: { BackButton, JsonViewer, JobOverlay },
    data() {
        return {
            loading: true,

            practice_key: "",
            jobData: null,
            cron_jobs: [],
        };
    },
    methods: {
        getCronJobs: function () {
            const url = make_url("/get-scheduled-jobs");

            this.loading = true;

            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            })
                .then(function (response) {
                    if (response) {
                        self.cron_jobs = response.data;
                    }
                })
                .finally(function () {
                    self.loading = false;
                });
        },
        clearCronJobs: function() {
            const url = make_url("/clear-scheduled-jobs");

            this.loading = true;

            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            })
                .then(function (response) {
                    if (response.data == "OK") {
                        self.cron_jobs = [];
                    }
                })
                .finally(function () {
                    self.loading = false;
                });
        },
        initCronJobs: function() {
            const url = make_url("/init-import-cron");

            this.loading = true;

            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            })
                .then(function (response) {
                    if (response.data == "OK") {
                        self.getCronJobs();
                    }
                })
                .finally(function () {
                    self.loading = false;
                });
        },
        runImportCron: function() {
            const url = make_url("/run-import-cron-now");

            this.loading = true;

            const self = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    practice: this.practice_key,
                }
            })
                .then(function (response) {
                    if (response.data) {
                        self.jobData = response.data;
                    }
                })
                .finally(function () {
                    self.loading = false;
                });
        },
    },
    computed: {
        job: function() {
            if (this.jobData !== null) {
                return this.jobData;
            }
            return null;
        }
    },
    mounted() {
        this.getCronJobs();
    },
};
</script>